<template>
  <div class="space-y-6 sm:px-6 lg:px-0 lg:col-span-9">
    <form @submit.prevent="onSubmit">
      <div class="shadow sm:rounded-md sm:overflow-hidden">
        <div class="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 class="text-lg leading-6 font-medium text-gray-900">Season</h3>
            <p class="mt-1 text-sm text-gray-500">This information will be displayed publicly so be careful what you share.</p>
          </div>
          <div class="grid grid-cols-3 gap-6">

            <SmallTextInput heading="Season Name" :value="state.name" @update:value="state.name=$event" :errors="v$.name.$errors"/>
            <LargeTextInput heading="Notes" :value="state.notes" @update:value="state.notes=$event" />

            <div class="bg-white py-6 px-4 space-y-6 col-span-full sm:p-6">

              <div class="grid grid-cols-6 gap-6">
                <DateInput heading="Start Date" :value="state.startDate" @update:value="state.startDate=$event" />
                <DateInput heading="End Date" :value="state.endDate" @update:value="state.endDate=$event" />
              </div>

            </div>

          </div>
        </div>
        <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button type="submit" class="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { reactive } from "vue";
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import SmallTextInput from "@/components/inputs/SmallTextInput";
import LargeTextInput from "@/components/inputs/LargeTextInput";
import DateInput from "@/components/inputs/DateInput";
import {useRoute} from "vue-router";

export default {
  name: "EditSeason",
  components: { DateInput, LargeTextInput, SmallTextInput },
  setup(){
    const route = useRoute()
    const store = useStore()
    store.dispatch('fetchSeason', route.params.id)
    const currentSeason = store.getters.getSeason
    const companyID = store.getters.getCompanyId

    const state = reactive({
      name: currentSeason.name,
      startDate: currentSeason.startDate,
      endDate: currentSeason.endDate,
      notes: currentSeason.notes,
      companyId: companyID,
    })

    const rules = {
      name: { required },
      startDate: { required },
      endDate: { required },
      notes: { required },
      companyId: {required}

    }
    const v$ = useVuelidate(rules, state, {$lazy: true})

    return {
      v$,
      state,
      store,
    }
  },
  methods: {
    async onSubmit() {
      this.v$.$touch()
      if (this.v$.$error) return
      await this.$store.dispatch('createSeason', this.state)
      await this.$router.push({ name: 'Seasons', params: { id: this.$store.getters.getSeasonID }})
    }
  }
}
</script>
